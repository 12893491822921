import {createClient} from 'contentful';
import {accessToken, spaceId} from './settings';

export const contentfulClient = createClient({
  space: spaceId,
  accessToken: accessToken,
  include: 2,
})

export const getContent = (contentType, order, limit, skip, ) => {
  return contentfulClient.getEntries({
    content_type: contentType,
    order: order,
    limit: limit,
    skip: skip
  })
}

export const getStory = slug => {
  return contentfulClient.getEntries({
    content_type: 'blogPost',
    'fields.slug': slug
  })
}
