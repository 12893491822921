import React from 'react';
import RichText from '@madebyconnor/rich-text-to-jsx';

export function EventItem({event, index}) {
    return (
      <div className={`col-12 col-lg-4 p-lg-2 p-sm-1 event-${index} text-left`}>
          <div className="col-12 event-box m-sm-1 m-0 mb-2 p-lg-2 pt-4 pb-4 pl-1 pr-1">
            <RichText richText={event.fields.content} />
          </div>
    </div>
    )
  }

export function EventsList({events}) {
    return (
        <div id="events" className="row events justify-content-between mb-5 mt-sm-5 mt-2 pb-5 no-gutters">
        <div className="col-12 text-right">
            <h1>What's cooking?</h1>
            <h3>Where to find us in Berlin.</h3>
        </div>
        {events.map((event, index) => (
            <EventItem event={event} index={index} key={event.sys.id} />
        ))}
        </div>
    )
}
