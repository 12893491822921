import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Story from './Story';
import * as serviceWorker from './serviceWorker';
import {
    Switch,
    Route,
    BrowserRouter as Router
  } from "react-router-dom";

  function Routes() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/events" component={App} />
          <Route exact path="/catering" component={App} />
          <Route exact path="/stories" component={App} />
          <Route path="/stories/:slug" component={Story} />
        </Switch>
      </Router>
    )
  }

  ReactDOM.render(
    <Routes />,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
