import React from 'react';
import RichText from '@madebyconnor/rich-text-to-jsx';
import * as moment from 'moment';
import {SlugContext} from '../context';
import { Element } from "react-scroll";

class BlogPostItem extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        active: props.blogPost.fields.slug === props.activeSlug ? true : false
      }
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
      let { updateURL, blogPost } = this.props
      this.setState({active: !this.state.active}, (state) => {
        if (this.state.active) {
          updateURL(blogPost.fields.slug);
        } else {
          updateURL("");
        }

      })
    }

    render() {
      const {blogPost} = this.props;
      const {active} = this.state;
      const datePost = moment(blogPost.fields.date).format("MMMM Do, YYYY");
      return (
          <div className={`${active ? "col-12 col-sm-6" : "col-12 col-sm-4"}  p-2 mb-lg-1 mb-0  stories-post ${active ? "post-active animated fadeIn" : "animated fadeIn"}`}>
            <div className="post-thumbnail animated fadeIn mb-sm-3 mb-2">
              <img className="img-post" src={blogPost.fields.image.fields.file.url}  alt={blogPost.fields.image.fields.name} />
            </div>
            <h3>{blogPost.fields.title}</h3>
            <div className="post-date">
              <p className="metadata">{datePost}</p>
            </div>
            <div className="read-more animated fadeIn">
              <p className="metadata"><button href="#" onClick={this.handleClick}>{`${active ? "Close Story" : "Read Story"} `}</button></p>
            </div>
            { active ?
            <div className="post-active-content animated fadeIn mt-4 pt-4">
                <RichText richText={blogPost.fields.postContent} />
            </div>
            : ''}
          </div>
      )
    }
  }

export function BlogPostsList({blogPosts}) {
  if (blogPosts) {
    return (
      <SlugContext.Consumer>
        {({slug, updateURL}) => (
          <Element name="stories" className="row stories mt-sm-5 mt-0 mb-sm-5 mb-3 pb-5 no-gutters">
            <div className="col-12 text-right">
              <h1>Stories</h1>
              <h3>As easy as pie.</h3>
            </div>
            {blogPosts.map(blogPost => (
              <BlogPostItem blogPost={blogPost} key={blogPost.sys.id} activeSlug={slug} updateURL={updateURL} />
            ))}
          </Element>
        )}
      </SlugContext.Consumer>
    )
  } else {
    return null
  }

}
