import React from 'react';

import Gif1 from './img/gif-humblepie-1.gif';
import Gif2 from './img/gif-humblepie-2.gif';

import "jquery";
import 'popper.js';
import 'bootstrap';

import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './style.css';
import './responsive.css';

import {contentfulClient, getContent} from './content';
import { BlogPostsList } from './components/BlogPosts';
import { EventsList } from './components/Events';
import { SlugContext } from './context';
import { Link, scroller } from "react-scroll";
import Header from './components/Header';
import Footer from './components/Footer';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.updateURL = (slug) => {
      this.props.history.push(`/stories/${slug}`);
    }
    this.state = {
      events: [],
      slug: this.props.match.params.slug
    }
    this.loadContent()
    this.handleSetActive = this.handleSetActive.bind(this);
    this.handleSetInactive = this.handleSetInactive.bind(this);
  }
  loadContent() {
    contentfulClient.getEntry("5QMdWUgFH1x2KVIwyWKTXV").then(entry => (this.setState({introImage: entry})))
    contentfulClient.getEntry("SaZimhGt63WP9vbCdgRL5").then(entry => (this.setState({cateringImage: entry})))
    contentfulClient.getEntry("6OgmtS3rLRvxDQatpe3PnV").then(entry => (this.setState({video: entry})))

    getContent("text", 'fields.order').then((response) => (
      this.setState({events: response.items})
    ))
    getContent("blogPost", '-fields.date').then((response) => (
      this.setState({blogPosts: response.items})
    ))
  }

  componentDidUpdate() {
    if (this.state.slug) {
      scroller.scrollTo("stories");
    }
  }

  handleSetActive(to) {
    this.props.history.push(`/${to}`);
  }

  handleSetInactive(to) {
    this.props.history.push('/');
  }

  scrollLogoMin() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("headerLogo").style.height = "50px";
  } else {
    document.getElementById("headerLogo").style.height = "110px";
  }
}

  render() {
    const {introImage, cateringImage, video, events, blogPosts, slug} = this.state;

    return (
      <div className="wrapper animated fadeIn">
        <Header handleSetActive={this.handleSetActive} handleSetInactive={this.handleSetInactive} />
        <div className="container-xl container-intro pt-lg-3 mt-lg-5 mt-5 mt-sm-0 pr-sm-5  pl-sm-5  pl-4 pr-4">
          <div className="row intro mt-lg-5 mt-4 mb-5 no-gutters">
            <div className="col-lg-5 col-md-7 col-12 mt-5">
              <h1>Hey Y'all!</h1>
              <h3>Humble Pie is a southern comfort food street kitchen serving up both sweet and savory treats. Specialities include southern fried chicken, Nashville hot chicken, buttermilk biscuits and sweet American pie.</h3>
              <h3>Look for the <Link spy={false} smooth={true} offset={-100} onSetActive={this.handleSetActive} onSetInactive={this.handleSetInactive} to="events">friendly red pie wagon</Link> at events around Berlin. Or, let us <Link spy={false} smooth={true} offset={-50} to="catering">cater your next event</Link>, office party or wedding.</h3>
            </div>
            <div className="gif-img col-5 offset-lg-1 offset-sm-0 offset-3 p-lg-5 pr-lg-3 text-center">
              <img className="img-fluid" src={Gif1} alt="meal gif" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row banner-video mt-sm-5 mb-sm-5 mt-3 mb-3 pb-5 no-gutters">
            <div className="col-12 text-center">
              <div className="image">
              { introImage &&
                <img className="img-full" src={introImage.fields.image.fields.file.url} alt={introImage.fields.image.fields.title} />
              }
              </div>
            </div>
          </div>
        </div>
        <div className="container-xl pt-lg-3 pr-sm-5 pl-sm-5  pl-4 pr-4">
          <EventsList events={events} />
        </div>
        <div className="container-fluid">
          <div className="row banner-2 mt-5 mb-5 pb-5 no-gutters">
            <div className="col-12 text-center">
              <div className="image">
                { cateringImage &&
                <img className="img-full" src={cateringImage.fields.image.fields.file.url} alt={cateringImage.fields.image.fields.title} />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="container-xl pt-lg-3 pr-sm-5 pl-sm-5  pl-4 pr-4">
          <div id="catering" className="row catering justify-content-between mt-sm-5 mt-0 mb-sm-4 mb-0 pb-4 no-gutters">
            <div className="col-lg-5 col-md-7 col-12 mt-sm-5 mt-0">
              <h1>Catering</h1>
              <h3>We like to party!</h3>
              <h3>Whether you are planning an office event, birthday or wedding, Humble Pie offers a full catering menu of southern treats. We can also bring out the pie wagon to make your event even more memorable! <a href="#contact">Contact us</a> to learn more.</h3>
            </div>
            <div className="gif-img col-5 offset-lg-1 offset-sm-0 offset-3 mt-3 mt-sm-0 p-lg-5 pr-lg-3 text-center">
              <img className="img-fluid" src={Gif2} alt="flying foodtruck" />
            </div>
          </div>
          <SlugContext.Provider value={{slug: slug, updateURL: this.updateURL}}>
            <BlogPostsList blogPosts={blogPosts} />
          </SlugContext.Provider>
        </div>
        <div className="container-fluid">
          <div className="row banner-video mt-5 mb-5 pb-5 no-gutters">
              <div className="col-12 text-center">
                <div className="video">
                  {video &&
                    <video className="video-full" autoPlay={true} loop={true} muted playsInline>
                     <source src={video.fields.image.fields.file.url} />
                   </video>
                  }
                </div>
              </div>
          </div>
        </div>
        <div className="container-xl">
          <div id="contact" className="row contact mt-sm-5 mb-sm-5 mt-3 mb-3 no-gutters">
              <div className="col-12 text-center pt-lg-5 pb-lg-5 mt-4 mb-4 pt-3 pb-3">
                <h1>chris@humblepie.de</h1>
                <h1>+49 (0) 176 657 35801</h1>
              </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
