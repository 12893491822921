import React from 'react';

const Footer = () => {
    return (
        <div className="container-fluid">
          <div className="row mt-5 mb-3 navbar-footer no-gutters">
            <div className="col-12">
              <nav className="navbar navbar-expand-sm navbar-light">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item pr-5">
                      <a className="nav-item nav-link title-menu" href="https://de-de.facebook.com/humblepieberlin/">Facebook</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-item nav-link title-menu" href="https://www.instagram.com/humblepieberlin/">Instagram</a>
                    </li>
                  </ul>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-item nav-link title-menu" href="#modal-impressum" data-toggle="modal" data-target="#modal-impressum">Impressum/Datenschutzerklärung</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-12 footer-mobile mb-2">
              <h1>Facebook - Instagram</h1>
              <h1>Impressum & Datenschultzerklärung</h1>
            </div>
            <div className="col-12 site-credits">
              <h3>website by ocré studio</h3>
            </div>
          </div>
        </div>
    )
}

export default Footer;
