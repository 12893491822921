import React from 'react';

export const slug = {
    slug: null
};

export const SlugContext = React.createContext({
    slug: slug.slug,
    updateURL: () => {}
})
