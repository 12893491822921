import React from 'react';
import {getStory} from './content';
import * as moment from 'moment';
import RichText from '@madebyconnor/rich-text-to-jsx';
import Header from './components/Header';
import Footer from './components/Footer';

class Story extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            story: null,
            active: true
        }
        this.loadStory();
    }
    loadStory() {
        getStory(this.props.match.params.slug).then(response => {
            this.setState({story: response.items[0]})
        })
    }
    render() {
        const {story, active} = this.state;
        if (!story) {
            return (
                <div></div>
            )
        }
        const datePost = moment(story.fields.date).format("MMMM Do, YYYY");

        return (
            <div className="wrapper animated fadeIn">
                <Header />
                <div className="container-xl mt-lg-5 mt-4 pt-5">
                    <div className={`col-12  p-2 mb-lg-1 mb-0  stories-post ${active ? "post-active animated fadeIn" : "animated fadeIn"}`}>
                        <div className="post-thumbnail animated fadeIn mb-sm-3 mb-2">
                        <img className="img-post" src={story.fields.image.fields.file.url}  alt={story.fields.image.fields.name} />
                        </div>
                        <h3>{story.fields.title}</h3>
                        <div className="post-date">
                        <p className="metadata">{datePost}</p>
                        </div>
                        <div className="read-more animated fadeIn">
                        </div>
                        <div className="post-active-content animated fadeIn mt-4 pt-4">
                            <RichText richText={story.fields.postContent} />
                            <div className="mt-5">
                              <h3 className="text-center" ><a href="/stories">Back to website</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Story;
