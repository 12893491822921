import React from 'react';
import logo from '../img/logo.png';
import { Link } from "react-scroll";

const Header = (props) => (
    <div className="container-fluid">
          <div className="row navbar-top no-gutters">
            <div className="col-12">
              <nav className="navbar navbar-expand-sm navbar-light fixed-top pl-5 pr-lg-5 pb-lg-5 ml-lg-5 mr-lg-5">
                <a className="navbar-brand" href="/"><img id="headerLogo"src={logo} alt="logo humble pie"/></a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item pr-5">
                      <Link spy={false} smooth={true} offset={-100} onSetActive={props.handleSetActive} onSetInactive={props.handleSetInactive} className="nav-item nav-link title-menu" to="events">Events</Link>
                    </li>
                    <li className="nav-item pl-4">
                      <Link spy={false} smooth={true} offset={-50} onSetActive={props.handleSetActive} onSetInactive={props.handleSetInactive} className="nav-item nav-link title-menu" to="stories">Stories</Link>
                    </li>
                  </ul>
                  <ul className="navbar-nav my-2">
                    <li className="nav-item pr-5">
                      <Link spy={false} smooth={true} onSetActive={props.handleSetActive} onSetInactive={props.handleSetInactive} className="nav-item nav-link title-menu" to="catering">Catering</Link>
                    </li>
                    <li className="nav-item pl-4">
                      <Link spy={false} smooth={true} onSetActive={props.handleSetActive} onSetInactive={props.handleSetInactive} className="nav-item nav-link title-menu" to="contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
)
export default Header
